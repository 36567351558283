import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import i18next from 'i18next';

interface IComponentProps {
  description?: string;
  lang?: string;
  meta?: [];
  title?: string;
}

const SEO = ({ description = '', lang = i18next.language, meta = [], title }: IComponentProps) => {
  const { site } = useStaticQuery(pageQuery);

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title || site.siteMetadata.title}
      titleTemplate={title && `%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        }
      ].concat(meta)}
    />
  );
};

export default SEO;

const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
